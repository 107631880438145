.wrapper {

    background-color: #222222;

}

main {
    position: relative;
    top: 50%;

}

.front-main {
    position: relative;


}

.main-image {
    width: 100%;
    height: auto;


}



.empty-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

}


.main-hover {
    background-color: rgba(0, 0, 0, 0.2);
}


.main-container {
    position: absolute;
    width: 83.4%;
    height: 93.5%;
    top: 1.2%;
    left: 10.4%;
    z-index: 12;


}

.main-container:hover {
    cursor: url('/public/images/mouse1.png'), auto;
    background-image: url('/public/images/bone1.png');
    background-repeat: no repeat;
    background-size: cover;
    background-position: center;


}

.blur {
    filter: blur(1px);
}


@media all and (max-width: 913px) {
    .main-image {
        position: fixed;
        left: -50%;
        width: 200%;
        height: 100vh;
    }

    .main-container {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 12;
    }

    .main-container:hover {
        background-image: none;
    }
}