.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
}

.box {
    height: 400px;
    width: 480px; /* 너비를 높이와 같게 설정하면 완벽한 원이 됩니다 (예: 200px) */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffe89d;
    border-radius: 140px; /* 이 값은 박스의 너비의 절반입니다 */
    overflow: hidden;
}

.percent {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    display: flex;

    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 50px;
    font-family: "SokchoBadaDotum";
}


.water {
    position: absolute;
    left: 0;
    top: 0;
    z-index:2;
    width: 100%;
    height: 100%;
    transform:translate(0,100%);
    background:#753f00;
    transition: all .3s;
}

.water_wave {
    width: 200%;
    position: absolute;
    bottom: 100%;
}

.water_wave_back {
    right: 0;
    fill: #ac856c;
    animation: wave-back 1.4s infinite linear;
}

.water_wave_front {
    left: 0;
    fill: #753f00;
    margin-bottom: -1.3px;
    z-index: 2;
    animation: wave-front .7s infinite linear;
}

@keyframes wave-front {
    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes wave-back {
    100% {
        transform: translate(50%, 0);
    }
}