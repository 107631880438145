@font-face {
    font-family: 'SokchoBadaDotum';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2402_1@1.0/SokchoBadaDotum.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Windlass";
    src: local("Windlass"),
        url("./assets/fonts/Windlass.ttf") format("truetype");
    font-weight: normal;

}



body {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    font-size: 14px;
    color: #333;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', "Windlass", 'SokchoBadaDotum',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: url('/public/images/mouse2.png'), auto;

}

a {
    color: inherit
}

img {
    vertical-align: top;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.popup-link {
    position: absolute;
    cursor: url('/public/images/CelCursor.9b13654f.png'), auto;
    z-index: 99;
}


::-webkit-scrollbar {
    display: none;
}